import { useState, useEffect, useRef } from 'react'
import { isAvailableSessionStorage } from 'utils/general'
import styles from './index.module.scss'

const AppRedirect = () => {
  const [closing, setClosing] = useState(false)
  const [showAppRedirect, setShowAppRedirect] = useState()
  const menuRef = useRef(null)

  useEffect(() => {
    setShowAppRedirect(
      !(isAvailableSessionStorage() && sessionStorage.getItem('appRedirect'))
    )
    setClosing(!showAppRedirect)

    // if (showAppRedirect) {
    //   document.addEventListener('mousedown', onClickOutside)
    // }

    // return () => {
    //   document.removeEventListener('mousedown', onClickOutside)
    // }
  }, [showAppRedirect])

  // const onClickOutside = (e) => {
  //   if (menuRef.current && !menuRef.current.contains(e.target)) {
  //     setTimeout(() => {
  //       closeBottomSheet()
  //     })
  //   }
  // }

  const closeBottomSheet = () => {
    if (isAvailableSessionStorage()) {
      sessionStorage.setItem('appRedirect', true)
    }
    setClosing(true)
  }

  const handleTransitionEnd = () => {
    if (closing) {
      setShowAppRedirect(false)
    }
  }

  return (
    <div
      data-test="app-redirect"
      className={`${showAppRedirect ? 'd-lg-none' : 'd-none'}`}
    >
      {/* <div
        onClick={closeBottomSheet}
        className={styles['bottom-sheet-overlay']}
        data-test="bottom-sheet-overlay"
      ></div> */}
      <div
        ref={menuRef}
        className={`${styles['app-redirect']} ${
          closing ? styles['closed'] : ''
        }`}
        onTransitionEnd={handleTransitionEnd}
      >
        <div
          onClick={closeBottomSheet}
          className={styles['close']}
          data-test="close-button"
        >
          &times;
        </div>
        <div className={styles['content']}>
          <img
            src={`${process.env.NEXT_PUBLIC_CDN_URL}/_next/static/images/touch-icon-mobile.png`}
            alt="İşin Olsun"
          />
          <div className={styles['slogan']}>
            Uygulamada daha fazlasını keşfet!
          </div>
          <a href="https://app.adjust.com/g3r0yk5">
            <button type="button" className="btn">
              Hemen İndir
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default AppRedirect
