import { memo } from 'react'
import Accordion from '@components/ui/footer-2/Accordion/accordion'
import { footerNavLinks, iLabBrands, socialLinks } from '../../../models/footer'
import styles from './index.module.scss'

const Footer2 = memo((props) => {
  const footerClass = props?.responsiveFooterDisable ? ' d-none d-lg-block' : ''
  const paddingClose = props?.footerPaddingClose ? ' d-none' : ''

  return (
    <div className={`${styles.root} ${footerClass} ${paddingClose}`}>
      <footer data-test="footer">
        <div className="container">
          <div className={styles.contentWrapper}>
            <div className={styles.content}>
              <img
                className={styles.footerLogo}
                src={`${process.env.NEXT_PUBLIC_CDN_URL}/_next/static/images/logo.png`}
                alt="İşin Olsun Logo"
                loading="lazy"
              />
              <div className={styles.navLinksWrapper}>
                {footerNavLinks?.map((section, index) => (
                  <div key={index} className={styles.section}>
                    <h4 className={styles.title} data-testid="product-title">
                      {section?.title}
                    </h4>
                    <ul>
                      {section?.links?.map((link, linkIndex) => (
                        <li className={styles.url} key={linkIndex}>
                          <a href={link?.url}>{link?.text}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              <Accordion footerNavLinks={footerNavLinks} />
            </div>
            <div className={styles.socialLinksWrapper}>
              <p>Sen de Bizi Takip Et</p>
              <div className={styles.socialLinks}>
                {socialLinks?.map((link, index) => (
                  <a
                    key={index}
                    data-test={link.dataTest}
                    href={link.href}
                    rel="noreferrer"
                    target="_blank"
                    aria-label={link.ariaLabel}
                  >
                    <i className={`${styles.icon} ${link.iconClass}`}></i>
                  </a>
                ))}
              </div>
              <div className={styles.downloadAppWrapper}>
                <p>Uygulamamızı İndir</p>
                <div className={styles.downloadImages}>
                  <a href="https://apps.apple.com/app/apple-store/id1226976149?pt=1379903&ct=isinolsun.com-footer&mt=8">
                    <img
                      className={styles.downloadApp}
                      src={`${process.env.NEXT_PUBLIC_CDN_URL}/_next/static/images/app-store.png`}
                      alt="App Store İndir Linki"
                      loading="lazy"
                    />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.isinolsun.app&rdid=com.isinolsun.app&referrer=utm_source%3Disinolsun.com%26utm_medium%3Dlink%26utm_campaign%3Dfooter-app-market">
                    <img
                      className={styles.downloadApp}
                      src={`${process.env.NEXT_PUBLIC_CDN_URL}/_next/static/images/google-play.png`}
                      alt="Google Play İndir Linki"
                      loading="lazy"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.brandsWrapper}>
            <div className={styles.brands}>
              <div className={styles.brandsInfo}>
                İşin Olsun bir
                <img
                  className={styles.kariyerIlabLogo}
                  src={`${process.env.NEXT_PUBLIC_CDN_URL}/_next/static/images/footer-kariyer-ilab.png`}
                  alt="Kariyet iLab Logo"
                  loading="lazy"
                />
                grup şirketidir.
              </div>
              <div className={styles.iLabBrands}>
                {iLabBrands?.map((brand, index) => (
                  <a
                    key={index}
                    href={brand?.url}
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    {' '}
                    {index !== 0 && '-'}{' '}
                    <span className={styles.brandName}>{brand?.name}</span>
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.infoWrapper}>
            <img
              className={styles.iskurLogo}
              src={`${process.env.NEXT_PUBLIC_CDN_URL}/_next/static/images/iskur-logo.png`}
              alt="İşkur Logo"
              loading="lazy"
            />
            <p className={styles.explainText}>
              Kariyer.net Elektronik Yayıncılık ve İletişim Hizmetleri A.Ş. Özel
              İstihdam Bürosu olarak 31/08/2024 - 30/08/2027 tarihleri arasında
              faaliyette bulunmak üzere, Türkiye İş Kurumu tarafından 26/07/2024
              tarih ve 16398069 sayılı karar uyarınca 170 nolu belge ile
              faaliyet göstermektedir. 4904 sayılı kanun uyarınca iş
              arayanlardan ücret alınması yasaktır.
            </p>
            <img
              className={styles.trustStamp}
              src={`${process.env.NEXT_PUBLIC_CDN_URL}/_next/static/images/trust-stamp.png`}
              alt="Güven Damgası"
              loading="lazy"
            />
          </div>
        </div>
      </footer>
    </div>
  )
})

export default Footer2
