import { useState, useCallback, memo, useEffect } from 'react'
import JobCardBasic2 from '@components/ui/jobcardbasic2'
import classNames from 'classnames'
import Carousel from '@brainhubeu/react-carousel'

import { isMobile } from 'utils/mobileDetect'
import {
  checkGeolocationPermission,
  geoLocationProcess,
  getSuggestJobsAll,
} from 'utils/general'
import { checkCookies, getCookies } from 'utils/cookies-next'
import HomeJobCardSkeleton from '../home-waiting-jobs-skeleton'
import styles from './index.module.scss'

const TabItem = memo(
  ({ item, index, slug, searchId, pageType, onOpenActivationDialog }) => {
    return (
      <JobCardBasic2
        order={index}
        onOpenActivationDialog={(message) =>
          onOpenActivationDialog(message, item.shareUrl + '?favorite')
        }
        fullBorder
        hasSingleLine
        hasFavorite
        disableFavoriteCallback
        searchId={searchId}
        pageType={pageType + '/ilan-modul/' + slug}
        {...item}
      />
    )
  }
)

const mobilePageSize = 3
const allText = 'Tümünü Gör'

const calcCarouselItem = (len) => {
  if (
    typeof len !== 'number' ||
    len <= 0 ||
    typeof mobilePageSize !== 'number' ||
    mobilePageSize <= 0
  ) {
    return []
  }

  const totalItems = Math.ceil(len / mobilePageSize)

  return Array.from({ length: totalItems }, (_, i) => i * mobilePageSize)
}

const getPaginatedJobs = (jobs, start) =>
  jobs.slice(start, start + mobilePageSize)

const calculateJobIndex = (index, slideIndex) => {
  const result = slideIndex * mobilePageSize
  return result + index + 1
}

const HomeJobs = memo(
  ({ items, onOpenActivationDialog, searchId, pageType }) => {
    const [itemList, setItemList] = useState(items)
    const [activeTab, setActiveTab] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const handleTabClick = useCallback((index, slug) => {
      dataLayer.push({
        event: 'ga4_event',
        event_name: 'select_tab',
        event_parameters: {
          widget: 'seni-bekleyen-ilanlar',
          tab: slug || undefined,
        },
      })

      setActiveTab(index)
    }, [])

    useEffect(() => {
      const handleGeolocation = async () => {
        try {
          setIsLoading(true)

          if (!navigator.geolocation) {
            setIsLoading(false)
            return
          }

          const permissionState = await checkGeolocationPermission()

          if (permissionState === 'granted' || permissionState === 'prompt') {
            navigator.geolocation.getCurrentPosition(
              checkLocationAndPullData,
              () => setIsLoading(false) // Hata durumunda loading kapatılır
            )
          } else {
            setIsLoading(false)
          }
        } catch (error) {
          setIsLoading(false)
        }
      }

      if (
        checkCookies(null, 'BlueCollar_UserLocationLat') &&
        checkCookies(null, 'BlueCollar_UserLocationLng')
      ) {
        suggestJobsAll()
        return
      }

      handleGeolocation()
    }, [])

    const suggestJobsAll = async () => {
      setIsLoading(true)

      try {
        const userLocationLat = getCookies(null, 'BlueCollar_UserLocationLat')
        const userLocationLng = getCookies(null, 'BlueCollar_UserLocationLng')

        const suggestJobsAll = await getSuggestJobsAll(
          null,
          userLocationLat,
          userLocationLng
        )
        setItemList(suggestJobsAll)
      } catch (error) {
        //
      }

      setIsLoading(false)
    }

    const checkLocationAndPullData = async () => {
      await geoLocationProcess()
      await suggestJobsAll()
    }

    return (
      <section>
        <div className={styles.root}>
          <div className="container">
            <h2 className={styles.title}>Seni Bekleyen İlanlar</h2>
            {/* Sekme başlıkları */}
            <div className={styles.tabs}>
              {itemList?.map((tab, tabIndex) => (
                <div
                  key={tabIndex}
                  className={classNames(styles.tabsItem, {
                    [styles.active]: activeTab === tabIndex,
                  })}
                  onClick={() => handleTabClick(tabIndex, tab.slug)}
                >
                  <span>{tab?.name}</span>
                </div>
              ))}
            </div>
            <div className={styles.listParent}>
              {isLoading && (
                <div className={styles.loadingPanel}>
                  <HomeJobCardSkeleton
                    amount={isMobile() ? mobilePageSize : 9}
                  />
                </div>
              )}

              {/* Sekme içerikleri */}
              {itemList?.map((tab, tabIndex) => (
                <div
                  key={tabIndex}
                  data-test={tab.slug}
                  className={`${styles.cardContainer} ${
                    activeTab == tabIndex ? styles.activeContainer : ''
                  }`}
                >
                  <div className={styles.desktopJobCards} data-test="desktop">
                    {tab?.jobs?.map((job, jobIndex) => (
                      <TabItem
                        key={jobIndex}
                        item={job}
                        index={jobIndex}
                        onOpenActivationDialog={onOpenActivationDialog}
                        searchId={searchId}
                        pageType={pageType}
                      />
                    ))}
                  </div>
                  <div className={styles.mobileJobCards} data-test="mobile">
                    <Carousel
                      dots
                      keepDirectionWhenDragging
                      className={styles.carousel}
                    >
                      {calcCarouselItem(tab?.jobs?.length).map(
                        (slideStart, slideIndex) => (
                          <div key={slideIndex} className={styles.slider}>
                            {getPaginatedJobs(tab.jobs, slideStart)?.map(
                              (job, jobIndex) => (
                                <TabItem
                                  key={calculateJobIndex(jobIndex, slideIndex)}
                                  item={job}
                                  index={calculateJobIndex(
                                    jobIndex,
                                    slideIndex
                                  )}
                                  onOpenActivationDialog={
                                    onOpenActivationDialog
                                  }
                                  searchId={searchId}
                                  pageType={
                                    pageType + '/ilan-modul/' + tab.slug
                                  }
                                />
                              )
                            )}
                          </div>
                        )
                      )}
                    </Carousel>
                  </div>
                  <div className="text-center">
                    <a
                      title={tab.name + ' ' + allText}
                      href={tab.href}
                      className={styles.url}
                    >
                      {allText}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    )
  }
)

export default HomeJobs
