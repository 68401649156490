export const getPxScriptContent = (pxCode) => `
(function() {
          window._${pxCode} = {
            challenge: {
                      translation: {
                          'default': {
                              'btn': 'Basılı Tut', //button text
                              'failed': 'Lütfen Tekrar Dene', // challenge failed message
                              'ctx_hdr': 'Kısa bir güvenlik doğrulaması<br>yapacağız…', // modal header
                              'ctx_msg': 'Aşağıdaki butona basılı tutarak doğrulamayı<br>gerçekleştirebilirsin.', //modal main message, supports html tags
                              'ctx_altmsg': 'Lütfen insan olduğunu (bot olmadığını) doğrula.', // main message for recaptcha (if used)
                              // feedback form translations
                              'ctx_frm': 'Doğrulama yaparken sorun yaşıyorum?',
                              'ctx_rid': 'Referans No',
                              'frm_hdr': 'Sorun Bildir',
                              'frm_msg': 'Bu sayfada sorun mu yaşıyorsun? Bizimle paylaşabilirsin:',
                              'frm_rid': 'Yardım için bizimle iletişime geçebilirsin. Referans No kullanmalısın',
                              'frm_fb': 'Doğrulama işlemiyle ilgili görüşlerini paylaşabilirsin:',
                              'frm_opt1': 'Doğrulama butonunu bulamıyorum',
                              'frm_opt2': 'Sürekli "Yeniden Dene" uyarısı alıyorum',
                              'frm_opt3': 'Diğer (lütfen aşağıda açıklayınız)',
                              'frm_inp': 'Başka bir sorunla mı karşılaştın?',
                              'frm_cxl': 'Vazgeç',
                              'frm_snd': 'Gönder',
                              'frm_thx': 'Geri bildirimin için teşekkür ederiz!',
                              // translations for accessible users
                              'ac_1': 'Doğrulama gerekiyor. Doğrulama tamamlanana kadar butona basılı tutmalısın.',
                              'ac_1a': 'Doğrulama gerekiyor. Doğrulama tamamlanana kadar butona basılı tutmalısın,erişilebilir bir sürüm için sekmeye basın',
                              'ac_1b': 'Doğrulama gerekiyor. Butona bir kez bas, onay bekle ve tekrar istenilirse aynılarını tekrarla.',
                              'ac_2': 'Doğrulama tamamlandı, birazdan kaldığın yerden devam edebileceksin.',
                              // accessibility email based solution translations
                              'ac_3': 'Erişilebilir doğrulama',
                              'ac_4': 'Devam etmek için geçici bir doğrulama koduna ihtiyacın var.',
                              'ac_5': 'E-posta adresini girmelisin.',
                              'ac_6': 'E-posta adresine geçici bir doğrulama kodu gönderdik.',
                              'ac_7': 'Kodu aşağıdaki alana girmelisin. Gelen kutuna gelen e-postayı kontrol edebilirsin.',
                              'ac_8': 'E-posta adresi',
                              'ac_9': "E-posta ulaşmadı mı?",
                              'ac_10': 'Yükleniyor',
                              'ac_11': 'Gönder',
                              'ac_12': 'Doğrulama kodu',
                              'ac_13': 'Kod hanesi',
                              'ac_14': 'Doğrulama',
                              'ac_15': 'Erişilebilir doğrulama',
                              'ac_16': 'İnsan doğrulama',
                              'ac_17': 'Geçerli bir e-posta adresi gerekli',
                              'ac_18': 'Lütfen bekle',
                              'ac_19': 'Lütfen tekrar dene',
                              // identified problems translations
                              'al_1': "Bir bağlantı sorunu var gibi görünüyor. Lütfen çevrimiçi olduğundan emin ol ve ardından sayfayı yenile.",
                              'al_2': 'Tarayıcında bir sorun var gibi görünüyor. Lütfen doğrulamayı yükleyebilmek için tarayıcı versiyonunu güncelle.'
                      }
                  },
                "view": {
                    "borderColor": "#8316B5",
                    "fillColor": "#8316B5",
                    "textColor": "#8316B5"
                }
            }
          };
      })();
      `
