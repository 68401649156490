import services from 'services'
import { normalizeString } from './stringOperation'

export const getAllPosition = async () => {
  const params = {
    positionJobType: 0,
  }

  let resp
  try {
    resp = await services.wrapper(null, services.configs.positions.get, params)
  } catch (error) {
    //
  }

  if (Array.isArray(resp?.data?.result) && resp.data.result.length > 0) {
    const normalizedItems = resp.data.result.map((item) => ({
      ...item,
      normalizedName: normalizeString(item.name),
    }))
    return normalizedItems
  }

  return false
}
