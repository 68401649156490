import { memo } from 'react'

import SearchInput2 from 'components/ui/searchinputs2'
import { locationMinLength, positionMinLength } from '../../../utils/general'

const HomeSearchV2 = memo((props) => {
  const positionList = props?.positionList || []
  const locationList = props?.locationList || []

  const onFormSubmit = (e) => {
    e.preventDefault()
    props?.onFormSubmit()
  }

  const onPositionFocus = () => {
    if (positionList.length == 0) {
      props.onPositionChange(props.positionKeyword)
    }
  }

  const onLocationFocus = () => {
    if (locationList.length == 0) {
      props.onLocationChange(props.locationKeyword)
    }
  }

  return (
    <>
      <form
        action="/is-ilanlari"
        data-test="main-search-form"
        onSubmit={(e) => onFormSubmit(e)}
      >
        <div className="root">
          <div
            className="search-input-wrapper"
            onFocus={(e) => onPositionFocus(e)}
          >
            <SearchInput2
              defaultKeyword={props?.positionKeyword}
              inputClassName="v2"
              menuClassName="v2"
              items={positionList}
              name="kw"
              id="position-search"
              labelKey="name"
              placeholder="Aradığın iş"
              icon="icon-search-thick"
              onValueChange={props?.onPositionChange}
              minLength={positionMinLength}
              dataTest="position-search-input"
            />
          </div>
          <div
            className="search-input-wrapper"
            onFocus={(e) => onLocationFocus(e)}
          >
            <SearchInput2
              defaultKeyword={props?.locationKeyword}
              inputClassName="v2"
              menuClassName="v2"
              items={locationList}
              name="lc"
              id="location-search"
              labelKey="name"
              placeholder="İş aradığın yer"
              icon="icon-location-thick"
              onValueChange={props?.onLocationChange}
              minLength={locationMinLength}
              dataTest="location-search-input"
            />
            {!props?.locationKeyword && (
              <span
                onClick={props?.onPullCurrentLocation}
                className="pull-location-icon"
              >
                {props?.locationProcess ? (
                  <span className="loading-location">
                    <img
                      className="loading-img"
                      src={`${process.env.NEXT_PUBLIC_CDN_URL}/_next/static/images/loading.gif`}
                      alt="Yükleniyor Görseli"
                    />
                  </span>
                ) : (
                  <i className="icon-location-pull" />
                )}
              </span>
            )}
          </div>

          <div className="main-search-button">
            <button
              data-test="main-search-button"
              type="submit"
              className="btn btn-default"
            >
              <span className="btn-text-1">Ara</span>
              <span className="btn-text-2">İş Bul</span>
            </button>
          </div>
        </div>
      </form>
      <style jsx global>{`
        .root {
          align-items: center;
          display: flex;
          gap: 12px;
          justify-content: space-between;
          margin: 24px 24px 24px 0;

          .search-input-wrapper {
            position: relative;
            height: 46px;
            width: 100%;

            &:hover,
            &:focus,
            &:focus-within {
              .search-input2.v2 input {
                padding: 0 42px 0 40px;
              }
            }

            .rbt {
              width: 100%;

              input {
                background: transparent;
                padding-right: 20px;
                width: 100%;
              }
            }

            .search-input2.v2 {
              align-items: center;
              background-color: #fff;
              display: flex;
              height: 46px;

              &:hover,
              &:focus,
              &:focus-within {
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);

                .clear-input.show {
                  align-items: center;
                  cursor: pointer;
                  display: flex;
                  height: 20px;
                  justify-content: center;
                  right: 11px;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 20px;
                }
              }
            }

            .search-input2.v2 .icon {
              font-size: 20px;
              top: 50%;
              transform: translateY(-50%);
            }

            .search-input2 .rbt-menu.v2 {
              width: 100%;
            }

            .loading-location {
              cursor: pointer;
              font-size: 20px;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              z-index: 1;
              color: #1298e6;

              .loading-img {
                width: 16px;
                vertical-align: baseline;
                z-index: 3;
              }
            }

            .pull-location-icon {
              color: #1298e6;
              cursor: pointer;
              font-size: 20px;
              height: 20px;
              position: absolute;
              right: 12px;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          .main-search-button {
            button {
              background-color: #e0212b;
              border-radius: 5px;
              border: 1px solid #e0212b;
              box-shadow: none;
              color: #fff;
              cursor: pointer;
              font-size: 14px;
              font-weight: 500;
              height: 46px;
              width: 120px;

              &:hover {
                background-color: #ca111b;
                color: #fff;
              }
            }
            .btn-text-2 {
              display: none;
            }
          }

          @media (max-width: 992px) {
            width: 100%;
            margin: 0;
            display: flex;
            flex-direction: column;

            .main-search-button {
              width: 100%;
              margin-bottom: 24px;

              button {
                margin-top: 0;
                width: 100%;
              }

              .btn-text-2 {
                display: initial;
              }
              .btn-text-1 {
                display: none;
              }
            }
          }
        }
      `}</style>
    </>
  )
})

export default HomeSearchV2
