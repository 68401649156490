import { homePopularServiceSection } from 'models/popular'
import { useState } from 'react'
import { ga4EventButtonClick, toUrl } from 'utils/general'
import styles from './index.module.scss'

const srcPath =
  process.env.NEXT_PUBLIC_CDN_URL + '/_next/static/images/features/services/'
const items = [
  {
    order: 1,
    title: 'Hizmet Talebi Oluştur',
    image: 'first.png',
    image2x: 'first@2x.png',
    desc: 'Dilediğin hizmet türünü seçerek ihtiyacın olan hizmetin detaylarını girebilirsin.',
  },
  {
    order: 2,
    title: 'Teklifleri Değerlendir',
    image: 'second.png',
    image2x: 'second@2x.png',
    desc: 'Gelen teklifleri değerlendirerek senin için en uygun teklifi seçebilirsin.',
  },
  {
    order: 3,
    title: 'Hemen İşini Hallet!',
    image: 'third.png',
    image2x: 'third@2x.png',
    desc: 'Teklifini seçtiğin hizmet verenle kolayca iletişim kurabilir ve hizmet işini yaptırabilirsin.',
  },
]

export default function Features() {
  const [selectedItem, setSelectedItem] = useState(items[0])

  const onMouseOver = (item) => {
    setSelectedItem(item)
  }

  const ga4EventWidgetClick = (label) => {
    dataLayer.push({
      event: 'ga4_event',
      event_name: 'widget_click',
      event_parameters: {
        widget: 'populer-hizmetler',
        label: toUrl(label) || undefined,
      },
    })
  }

  return (
    <section className={styles.features2}>
      <div className="container">
        <div data-test="features-head" className={styles.head}>
          <h2>İşinin Ustalarıyla Tanış</h2>
          <p>
            Evini boyatacak veya tadilat mı yaptıracaksın? Onlarca hizmet
            arasından sana uygununu seç ve hizmet talebi oluştur!
          </p>
        </div>
      </div>

      <div className="container">
        <div data-test="features-content" className={styles.content}>
          <div className="row">
            <div className="col-lg-6">
              <div className={styles.img}>
                <img
                  src={`${srcPath}${selectedItem.image}`}
                  srcSet={`${srcPath}${selectedItem.image2x} 2x`}
                  alt="İşin Olsun Hizmet"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className={styles.items}>
                <ul data-test="features-numbers" className={styles.list}>
                  {items.map((item, index) => {
                    return (
                      <li
                        data-test="features-number"
                        onMouseOver={() => onMouseOver(item)}
                        key={index}
                        className={`${styles.item} ${
                          selectedItem.order == item.order ? styles.active : ''
                        }`}
                      >
                        <div className={styles.icon}>
                          <span className={styles.number}>{item.order}</span>
                        </div>
                      </li>
                    )
                  })}
                </ul>

                <ul data-test="features-list" className={styles.list}>
                  {items.map((item, index) => {
                    return (
                      <li
                        data-test="features-item"
                        onMouseOver={() => onMouseOver(item)}
                        key={index}
                        className={`${styles.item} ${
                          selectedItem.order == item.order ? styles.active : ''
                        }`}
                      >
                        <div className={styles.detail}>
                          <h3>{item.title}</h3>
                          <p>{item.desc}</p>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className={styles.chips}>
          <h2>Popüler Hizmetler</h2>
          <ul>
            {homePopularServiceSection?.map((item, index) => (
              <li
                onClick={() => ga4EventWidgetClick(item?.positionName)}
                key={index}
              >
                <a
                  href={`${process.env.NEXT_PUBLIC_MAIN_URL2}/hizmet/${toUrl(
                    item.positionName,
                    true
                  )}`}
                >
                  {item.positionName}
                </a>
              </li>
            ))}
          </ul>
          <div className={styles.buttons}>
            <a
              onClick={() => ga4EventButtonClick('talep-olustur')}
              href={`${process.env.NEXT_PUBLIC_MAIN_URL2}/hizmet`}
              className={styles.button}
            >
              Talep Oluştur
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
