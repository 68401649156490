import { getPxScriptContent } from 'utils/human'
import '../assets/style/style.scss'
import ProgressBar from '@components/ui/progress-bar'
import Head from 'next/head'
import { GlobalContextProvider } from '../contexts/globalcontextt'

const PxScript = () => {
  if (!process.env.NEXT_PUBLIC_HUMAN_CODE) return null

  const pxCode = process.env.NEXT_PUBLIC_HUMAN_CODE
  const scriptContent = getPxScriptContent(pxCode)

  return (
    <Head>
      <script dangerouslySetInnerHTML={{ __html: scriptContent }} />
    </Head>
  )
}

function MyApp({ Component, pageProps }) {
  return (
    <GlobalContextProvider pageProps={pageProps}>
      <ProgressBar />
      <PxScript />
      <Component {...pageProps} />
    </GlobalContextProvider>
  )
}

export default MyApp
